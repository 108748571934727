//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import Calendar from "@/components/Calendar.vue";
import { dateRangeBoundaries, dateRangeFromDate, today } from "@/utils/dates";
import StorageTable from "@/components/Connections/StorageTable";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import dayjs from "dayjs";
import MyShopsMixin from "@/mixins/MyShops";
import HasTokens from "@/components/HasTokens";
const FORMAT = "YYYY-MM-DD";
export default {
  mixins: [CalendarMixin, MyShopsMixin],
  meta: {
    title: "Склад"
  },
  data() {
    return {
      calendarInternal: ""
    };
  },
  methods: {
    loadCalendar() {
      var _this$calendarBoundar;
      if (!this.calendarBoundaries) {
        this.calendar = null;
        return;
      }
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 30);
      this.calendar = dateRangeBoundaries([weekRange[1]], this.calendarBoundaries);
    },
    loadCalendarBoundaries() {
      const date2 = new Date();
      date2.setDate(date2.getDate());
      this.calendarBoundaries = ["2022-03-26", date2.toISOString().split("T")[0]];
    }
  },
  created() {
    this.loadCalendarBoundaries();
    this.loadCalendar();
  },
  computed: {
    fileName() {
      const split = this.reportSettings.date.split("-");
      const date = split[1] + "-" + split[0] + "-" + split[2];
      return `${this.marketplace.title} Склад ${date}`;
    },
    boundariesDates() {
      return this.calendarBoundaries.map(i => i ? dayjs(i, FORMAT) : i);
    },
    calendar: {
      get: function () {
        return this.calendarInternal;
      },
      set: function (v) {
        this.calendarInternal = v[0];
      }
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      const split = this.calendar.split("-");
      const date = split[1] + "-" + split[2] + "-" + split[0];
      return {
        date: date,
        tokens: this.selectedTokens.map(item => item.id)
      };
    }
  },
  watch: {
    calendarBoundaries() {
      this.loadCalendar();
    }
  },
  components: {
    MainTitle,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    StorageTable,
    Calendar,
    HasTokens,
    DataLoading,
    DataTableActions
  }
};