//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    async tableLoader({
      query
    }) {
      let date = this.reportSettings.date;
      if (this.reportSettings.date2) date = this.reportSettings.date2;
      return await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbStorage", "connections/getOzStorage", {
        date
      }, {
        date
      });
    }
  },
  computed: {
    columns() {
      // const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      return this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "SKU",
        show: true,
        name: "nmid",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "Артикул",
        show: true,
        name: "supplierArticle",
        type: "text",
        width: 130,
        filter: "text",
        noOverflow: true
      }, {
        title: "Бренд",
        show: true,
        name: "brand",
        type: "text",
        width: 160,
        filter: "text"
      }, {
        title: "Наименование",
        show: true,
        name: "subject",
        type: "text",
        width: 220,
        filter: "text",
        getImage: item => item.image
      }, {
        title: `Размер`,
        show: true,
        name: "techSize",
        type: "text",
        width: 180,
        filter: "text"
      }, {
        title: `Категория`,
        show: true,
        name: "category",
        type: "text",
        width: 180
      }, {
        title: `Кол-во (всего)`,
        show: true,
        name: "Quantity",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Кол-во полное (всего)`,
        show: true,
        name: "quantityFull",
        type: "number",
        width: 220,
        filter: "numberRange"
      }, {
        title: `Кол-во не в заказе (всего)`,
        show: true,
        name: "quantityNotInOrders",
        type: "number",
        width: 220,
        filter: "numberRange"
      }, {
        title: `Цена`,
        show: true,
        name: "Price",
        type: "money",
        width: 120,
        filter: "numberRange"
      }, {
        title: `Дисконт`,
        show: true,
        name: "Discount",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: `Штрих-код`,
        show: true,
        name: "Barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `Код контракта`,
        show: true,
        name: "SCCode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `В пути к клиенту (всего)`,
        show: true,
        name: "inWayToClient",
        type: "number",
        width: 220,
        filter: "numberRange"
      }, {
        title: `В пути от клиента (всего)`,
        show: true,
        name: "inWayFromClient",
        type: "number",
        width: 220,
        filter: "numberRange"
      }, {
        title: `Дата`,
        show: true,
        name: "snapshotDate",
        type: "date",
        width: 160,
        filter: "dateRange"
      }, {
        title: `Дата обновления`,
        show: true,
        name: "lastChangeDate",
        type: "text",
        width: 160,
        filter: "dateRange"
      }] : [{
        title: "SKU",
        show: true,
        name: "sku",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "Артикул",
        show: true,
        name: "offer_id",
        type: "text",
        width: 130,
        filter: "text",
        noOverflow: true
      }, {
        title: `Категория`,
        show: true,
        name: "category_name",
        type: "text",
        width: 180
      }, {
        title: "Наименование",
        show: true,
        name: "name",
        type: "text",
        width: 500,
        filter: "text",
        getImage: item => item.image
      }, {
        title: `Цена`,
        show: true,
        name: "price",
        type: "money",
        width: 100,
        filter: "numberRange"
      }, {
        title: `Штрих-код`,
        show: true,
        name: "barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      },
      /* //&: 2023-04-17
      //   {
      //       title: `Статус`,
      //       show: true,
      //       name: "status",
      //       type: "text",
      //       width: 120,
      //       filter: "text",
      //   },
      //   {
      //       title: `Видимость на сайте`,
      //       show: true,
      //       name: "visible",
      //       type: "text",
      //       width: 180,
      //       filter: "text",
      //   },
      */
      {
        title: `Дата`,
        show: true,
        name: "snapshot_date",
        type: "date",
        width: 160,
        filter: "dateRange"
      }, {
        title: `Дата обновления`,
        show: true,
        name: "updated_at",
        type: "text",
        width: 160,
        filter: "dateRange"
      }, {
        title: `Доступно (всего)`,
        show: true,
        name: "quantity",
        type: "number",
        width: 140,
        filter: "numberRange"
      }];
    }
  }
};